import React from "react";
import { PageWrapper, TitleWrapper, UlWrapper } from "./wrappers.tsx";

export const QuestionsAndAnswers = () => {

  return (
  <PageWrapper>
    <TitleWrapper>Q & A</TitleWrapper>
    <h3>What is a coliving?</h3>
    <p>
    Coliving is a dynamic and community-oriented lifestyle that goes beyond simply sharing a space. It’s a life choice embraced by people who value a sense of belonging and connection with others. In a coliving environment, residents come together to create a close-knit community, fostering meaningful relationships and shared experiences. </p>
    <p>
    Imagine living in a place where you not only have your own private space but also share common areas like kitchens, living rooms, and communal spaces with like-minded individuals. It’s not just a place to stay; it’s an opportunity to build lasting friendships, collaborate on projects, and support each other’s personal and professional growth. Whether you’re an aspiring artist, entrepreneur, or simply someone who thrives in a social environment, coliving offers a platform to connect, collaborate, and create. 
    </p>
    <p>
    You’ll find yourself surrounded by diverse individuals with unique backgrounds and talents, all contributing to the vibrant tapestry of the community. Coliving focuses on shared values, experiences, and a commitment to enriching everyday life through communal living. It’s a space where everyone plays a role in shaping the community, where your roommates become your friends, and where you can truly experience the joys of living together.
    </p>
    <h3>What are the Benefits of Coliving?</h3>
    <p>Coliving is like a modern version of traditional roommates, but it’s so much more. It’s about forming a united community where you live, work, and grow alongside others. You share expenses, making it affordable, and often have access to great amenities. </p>
    <p>It’s a fantastic way to meet diverse and interesting people and build lasting friendships. Plus, it can be a springboard for personal and professional growth. It’s like having a built-in support system and a fun, vibrant lifestyle all in one.</p>
    <h3>How is Coliving Different from Traditional Renting?</h3>
    <p>Coliving feels like choosing a fun, supportive family instead of renting alone. You share your space, expenses, and life moments with a diverse group of friends. It’s about community, shared experiences, and less isolation. In traditional renting, it’s more independent, often lonelier, and less communal.</p>
    <p>There are no hidden costs; the payment covers all expenses like gas, electricity, and cleaning. It’s designed so you can be productive, work, and have fun without the worries of traditional renting.</p>
    <h3>Who is Coliving For?</h3>
      <UlWrapper>    
        <li><b>Digital Nomads:</b> People who work remotely and seek a comfortable place with good internet.</li>
        <li><b>Freelancers and Entrepreneurs:</b> Professionals who value social interaction and collaboration opportunities.</li>
        <li><b>Travelers:</b> People who want to live in different places without committing to traditional rentals.</li>
        <li><b>Social Individuals:</b> Those who enjoy living with others and want to be part of a community.
        </li>
        <li><b>Nature Lovers:</b> Many coliving spaces are located in natural or rural settings.</li>
      </UlWrapper>
    <h3>Who is Coliving NOT For?</h3>
      <UlWrapper>
        <li>You’re on vacation and expect to be served and cleaned up after.</li>
        <li>You just want a place to stay, but most accommodations are too expensive.</li>
        <li>You don’t like animals and aren’t too concerned about the environment.</li>
        <li>You don’t plan to socialize or contribute to the community.</li>
        <li>You’re ONLY interested because it’s “Instagrammable.”</li>
        <li>You can’t live without a TV.</li>
        <li>You can’t adapt to cultural differences and judge people or situations too quickly.</li>
        <li>You enjoy drama or gossip.</li>    
      </UlWrapper>
    <h3>What are Skill Shares?</h3>
    <p>Coliving organizes activities around the concept of skill sharing. Residents share their skills with the community. Examples: How blockchain works, front-end development, finding work as a developer, learning to paint with watercolors, managing and monetizing social media.
    </p>
    <p>
    Skillshare is like a hidden treasure within a close-knit community. It's all about residents coming together to share their passions, talents, and expertise. Imagine a neighbor teaching you how to cook their family's secret recipe, another resident leading a yoga class in the communal area, or someone offering lessons in a musical instrument they love.
    </p>
    <p>
      It's not just about learning; it's about connecting on a personal level. You might discover shared hobbies, unlock hidden talents, or simply bond with your neighbors over shared interests. Skillshare in coliving adds depth to your living experience, making it more enriching and enjoyable. It's a chance to both teach and learn, fostering a sense of community that goes beyond the typical shared living space
    </p>
    <h3>Mastermind </h3>
    <p>
      A mastermind in a coliving context is like having your own personal board of advisors within your living community. It's a small, carefully curated group of like-minded individuals who come together to support each other's personal and professional growth.
    </p>
    <p>
      In a coliving mastermind, residents share their goals, challenges, and aspirations. They provide feedback, insights, and ideas to help each other overcome obstacles and achieve success. Whether it's brainstorming a new business idea, offering career advice, or simply providing emotional support, a coliving mastermind creates a safe and confidential space for residents to collaborate and grow together.
    </p>
    <p>
      It's a powerful concept that leverages the diversity and expertise of the community, fostering personal development, networking, and a strong sense of camaraderie among residents.
    </p>
    <h3>Family dinner</h3>
    <p>
    A family dinner is like a cozy, regular gathering where all residents sit down to share a meal. It's more than food; it's about bonding, laughter, and building a close-knit community. Imagine enjoying homemade dishes from different cultures while chatting with your extended coliving family. It's a heartwarming tradition that turns neighbors into friends.
    </p>
    <h3>Hikings</h3>
    <p>
    Picture exploring scenic trails and natural wonders together, from lush forests to breathtaking mountain views. It's not just about the physical activity but also about forging deeper connections, swapping stories, and making memories that'll last a lifetime. In coliving, hiking becomes a shared passion that brings residents closer and allows them to appreciate the beauty of nature as a community.
    </p>
    <h3>Board games</h3>
    <p>
    Board games are like a secret ingredient that adds a unique flavor to our shared experiences. It's those nights when we gather in the common area, each bringing our favorite games to the table. As we roll the dice, strategize, and compete, it feels like we're creating our own little world of excitement and connection.
    In those moments, it's not just about winning or losing; it's about the joy of spending quality time with roommates who become friends and friends who become like family. We share inside jokes, cheer for each other's victories, and even playfully argue over the rules. These board game nights are the heartwarming memories that make coliving truly special.
    </p>
    <h3>What Amenities are Typically Included in a Coliving Space?</h3>
    <p>Coliving spaces usually offer a range of amenities: furnished rooms, cleaning services, Wi-Fi, and often social areas like lounges and kitchens. It’s like a comfortable, ready-to-use home where you can focus on living and connecting with others.</p>
    <h3>
    What is the Duration of a Coliving Agreement? Can I Rent Short-Term?
    </h3>
    <p>Coliving agreements vary, but many offer flexible lease terms, including short-term options. It’s like tailor-made renting to suit your needs. Most have a minimum stay of 15 days so you can fully immerse yourself in the experience.</p>
    <h3>
    What is the Community Like in a Coliving Space? How is it Maintained?
    </h3>
    <p>Coliving fosters a close-knit community through shared spaces and activities. Maintenance often involves regular events, shared responsibilities, and a sense of belonging. It’s like living among friends who help create a vibrant atmosphere.</p>
  </PageWrapper>
  );
};