import styled from 'styled-components';

export const BlogWrapper= styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;
  margin: 0.5em 0;
`

export const ImageWrapper = styled.img`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-height: 230px;
  object-fit: cover;
`

export const TextWrapper = styled.div`
  border-left: 1px solid grey;
  padding-left: 0.5em;
  margin: 0.5em 0;
`

export const SpanWrapper = styled.span`
  cursor:pointer;
`