export const footerLinks = [
  {
    id:1,
    title: 'Coliving in Spain',
    url: 'spain',
    label: 'Spain'
  },
  {
    id:2,
    title: 'Coliving in France',
    url: '/france',
    label: 'France'
  },
  {
    id:3,
    title: 'Coliving in Italy',
    url: '/italy',
    label: 'Italy'
  },
  {
    id:4,
    title: 'Coliving in Portugal',
    url: '/portugal',
    label: 'Portugal'
  },
  { 
    id:5,
    title: 'Coliving in Germany',
    url: '/germany',
    label: 'Germany'
  }
];

export const footerFAQLinks = [
  {
    id:1,
    title: 'What is coliving?',
    url: '/what-is-coliving',
    label: 'What is coliving?'
  },
  {
    id:2,
    title: 'What are the Benefits of coliving?',
    url: '/what-are-the-benefits-of-coliving',
    label: 'What are the Benefits of coliving?'
  },
  {
    id:3,
    title: 'How is Coliving Different from Traditional Renting?',
    url: '/how-is-coliving-different-from-traditional-renting',
    label: 'How is Coliving Different from Traditional Renting?'
  },
  {
    id:4,
    title: 'Who is Coliving For?',
    url: '/who-is-coliving-for',
    label: 'Who is Coliving For?'
  },
  {
    id:5,
    title: 'Who is Coliving NOT For?',
    url: '/who-is-coliving-not-for',
    label: 'Who is Coliving NOT For?'
  },
  {
    id:6,
    title: 'What are Skill Shares?',
    url: '/what-are-skill-shares',
    label: 'What are Skill Shares?'
  },
  {
    id:7,
    title: 'What amenities are typically included in a co-living space?',
    url: '/what-amenities-are-typically-included-in-a-coliving-space',
    label: 'What amenities are typically included in a co-living space?'
  },
  { 
    id:8,
    title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
    url: '/what-is-the-duration-of-a-coliving-agreement',
    label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  },
  { 
    id:9,
    title: 'What is the community like in a co-living space? How is it maintained?',
    url: '/what-is-the-community-like-in-a-co-living',
    label: 'What is the community like in a co-living space? How is it maintained?'
  },
  { 
    id:10,
    title: 'What are the rules and policies regarding guests and visitors?',
    url: '/what-are-the-rules-and-policies-regarding-guests-and-visitors',
    label: 'What are the rules and policies regarding guests and visitors?'
  },
  { 
    id:11,
    title: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?',
    url: '/what-is-the-duration-of-a-coliving-agreement',
    label: 'What is the duration of a co-living agreement? Can I rent on a short-term basis?'
  },
];