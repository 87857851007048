import React from "react"
import { BlogTypes } from "./types"
import { BlogWrapper, ImageWrapper, TextWrapper, SpanWrapper } from "./wrappers.tsx"


export const Blog = ({title, subtitle, description, picture, pictureLabel, onClick}:BlogTypes) => {

  return (
      <BlogWrapper>
        <TextWrapper>
          <p>{subtitle}</p>
          <h2>{title}</h2>
        </TextWrapper>
        <ImageWrapper src={picture} alt={pictureLabel} />
        <TextWrapper>
          <p>{description}</p>
          <SpanWrapper onClick={onClick}>More  --</SpanWrapper> 
        </TextWrapper>
      </BlogWrapper>
    )
}