import React, { useState } from "react"
import { ContactWrapper, Container, RightContactWrapper, TextareaWrapper, InputWrapper, ButtonWrapper } from './wrappers.tsx'
import emailjs from 'emailjs-com';

export const About = () => {
    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    
    const handleEmailChange = (e) => {
    setEmail(e.target.value);
    };

    const handleMessageChange = (e) => {
    setMessage(e.target.value);
    };
    
    const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateEmail(email)) {
            console.log('Invalid email');
            return;
        }
        const templateParams = {
            from_name: name,
            from_email: email,
            message: message,
            subject: subject
        };
        emailjs
            .send(
                process.env.REACT_APP_EMAIL_API_KEY || '',
                process.env.REACT_APP_EMAIL_TEMPLATE_KEY || '',
                templateParams,
                process.env.REACT_APP_EMAIL_PUBLIC_KEY || ''
            )
            .then((response) => {
                console.log('Email sent successfully!', response);
                // TODO: Show success message or perform any other actions
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                // TODO: Show error message or perform any other actions
            });
            // Reset form fields
            setName('');
            setEmail('');
            setSubject('')
            setMessage('');
    };

    return (
        <Container>
            <h1>About us</h1>
            <h3>What do we do?</h3>
            <p>The colivings is a platform of colivings where you can search and book colivings all over the world.</p>
            <p>Through shared experiences, from family dinners to outdoor adventures, we're reshaping the digital nomad experience by providing a global platform</p>
            <p>that seamlessly connects them with trusted coliving spaces, fostering cultural exploration and worldwide connections.</p>
            <p>Imagine a place where you not only have your own private corner but also share experiences, laughter, and projects with people who, like you, are looking for more than just a roof over their heads. That’s coliving: a lifestyle that combines comfort, community, and connection. It’s not just about living together; it’s about growing together.</p>
            <p>If you’re someone who works from anywhere, loves to travel and discover new corners of the world, or simply seeks a place to feel part of something bigger, coliving is for you. Here, your neighbors aren’t just people who live nearby—they’re allies, friends, and adventure companions. </p>
            <p>Together, you’ll share not just the kitchen or living room but also ideas, knowledge, and unforgettable moments.</p>
            <p>Coliving is flexible, affordable, and full of life. No need to worry about endless bills or long contracts: everything is designed so you can focus on what really matters—working, creating, and enjoying. Plus, with activities like workshops, excursions, or simply a shared dinner, every day is an opportunity to learn something new or connect with someone amazing.</p>
            <p>But beware! Coliving isn’t for everyone. If you’re looking for a place where everything is served to you on a silver platter or prefer isolation, it might not be for you. Here, everyone contributes: you clean up after yourself, respect the shared space, and, above all, bring positive energy to the community. It’s a constant exchange: you give a little of yourself and receive so much in return.</p>
            <p>In short, coliving is for those seeking a home with soul, where walls aren’t limits but doors to new friendships and experiences. If you dream of a place to work, live, and connect authentically, coliving is waiting for you. Dare to try it? 🏡✨</p>
            <ContactWrapper>
                <h1>Contact us</h1>
                <p>Do you have a coliving space you'd like to feature here? </p>
                <p>We're dedicated to showcasing all the options available to digital nomads </p>
                <p>for a complete experience. Let's connect!</p>
                
                <form onSubmit={handleSubmit}>
                    <RightContactWrapper>
                            <InputWrapper
                            type="text"
                            id="name"
                            placeholder='Name'
                            value={name}
                            onChange={handleNameChange}
                            />
                            <InputWrapper
                            type="email"
                            id="email"
                            placeholder='Email'
                            value={email}
                            onChange={handleEmailChange}
                            />
                            <InputWrapper
                            type="text"
                            id="subject"
                            value={subject}
                            placeholder='Subject'
                            onChange={handleSubjectChange}
                            />
                            <TextareaWrapper
                            id="message"
                            value={message}
                            onChange={handleMessageChange}
                            placeholder='Message'
                            /> 
                            <ButtonWrapper type="submit">Send Email</ButtonWrapper>
                    </RightContactWrapper>
                </form>
            </ContactWrapper>
        </Container>
    )
}