import { api } from "../api.ts";

export const createNewReservation = async (
  colivingId: string,
  roomId: string,
  startDate: string,
  endDate: string,
  token: string // Add token parameter
) => {
  const response = await api.post(
    `reservations/checkout-session/${colivingId}/${roomId}`,
    {
      start_date: startDate,
      end_date: endDate,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );
  return response.data;
};