import {
  Routes,
  Route,
} from "react-router-dom";
import { About } from './containers/About/index.tsx'
import { Find } from './containers/Find/index.tsx'
import { QuestionsAndAnswers } from './containers/Q&A/index.tsx'
import { Coliving } from './containers/Coliving/index.tsx'
import { NotFound } from './components/NotFound/index.tsx'
import {footerLinks, footerFAQLinks} from './mocks/footerlinks/index.ts'
import { GeneralWrapper } from './appWrapper/generalWrapper.ts'
import  {Header}  from './containers/Header/index.tsx'
import { Footer } from './containers/Footer/index.tsx'
import { colors } from './assets/colors/index.tsx';
import { SignUp } from "./components/SignUp/index.tsx";
import { Login } from "./components/Login/index.tsx"
import { Profile } from "./containers/Profile/index.tsx";
import { Blog } from "./components/Blog/index.tsx";

function App() {
  return (
    <>
      <Header />
      <GeneralWrapper>
        <Routes>
          <Route exact path="/" element={<Find />} />
          <Route path="/about" element={<About />} />
          <Route path="/questions-and-answers" element={<QuestionsAndAnswers />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/coliving-madrid" element={<Coliving />} />
          <Route path="/coliving-madrid-blog" element={<Blog />} />
          <Route path="/profile" element={<Profile />} />
          {footerLinks.map((link) => (
            <Route key={link.id} path={`/${link.url}`} element={<Find />} />
          ))}
          {footerFAQLinks.map((link) => (
            <Route key={link.id} path={`/${link.url}`} element={<About />} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </GeneralWrapper>
      <Footer color={colors.primary} background={colors.secondary} />
    </>
  );
}

export default App;