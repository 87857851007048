import React, { useEffect, useState } from "react";
import { Container, CalendarWrapper, LogoWrapper, ColivingNameWrapper, SocialNetworkLogosWrapper, SocialNetworkContainer, FormWrapper } from "./wrappers.ts";
import { ImageGallery } from "../../components/ImageGallery/index.tsx";
import { Calendar } from "../../components/Calendar/index.tsx";
import dayjs from 'dayjs';
import { ButtonWrapper, InputWrapper, TextareaWrapper } from "../About/wrappers.tsx";
import emailjs from 'emailjs-com';
import colivingMadrid from '../../images/Co-living-Madrid-logo.png';
import logoInstagram from '../../images/iglogo.png';
import { images, mockedRooms } from '../../mocks/colivings/coliving-madrid/index.tsx';
import { Room } from "../../components/Room/index.tsx";
import { Reviews } from "../../components/Reviews/index.tsx";
import { getColiving } from "../../services/coliving/index.ts";
import { createNewReservation } from "../../services/reservations/index.ts";
import { getToken } from '../../utils/authHelpers.ts';
import { AuthDialog } from "../../components/AuthDialog/index.tsx";
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';

//id of coliving madrid
const colivingId = '66fe7c54eb265f9a598768fe'

export const Coliving = () => {
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('booking');
  const [isSubmitDone, setIsSubmitDone] = useState(false)
  const [coliving, setColiving] = useState([]);
  const [visitedColiving, setVisitedColiving] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState('');
  const token = getToken()
  const [open, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    message: '',
    room: '',
    visitedColiving: ''
  });

  const handleRoomChange = (e) => {
    setSelectedRoomId(e.target.value);
    setFormErrors(prev => ({...prev, room: ''}));
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
    setFormErrors(prev => ({...prev, name: ''}));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (validateEmail(e.target.value)) {
      setFormErrors(prev => ({...prev, email: ''}));
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    setFormErrors(prev => ({...prev, message: ''}));
  };
  
  const today = dayjs();

  const handleCheckinChange = (newDate) => {
    setCheckinDate(newDate);
  };

  const handleCheckoutChange = (newDate) => {
    setCheckoutDate(newDate);
  };
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isInvalidStay = checkoutDate && checkinDate && checkoutDate.diff(checkinDate, 'day') < 15;

  useEffect(() => {
    const fetchColiving = async () => {
      try {
        const fetchColiving = await getColiving(colivingId);
        setColiving(fetchColiving);
      } catch (error) {
        console.error("Failed to fetch reviews:", error);
      }
    };
    fetchColiving();
    // Get the token and set it in state
  }, []); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Reset previous errors
    setFormErrors({
      name: '',
      email: '',
      message: '',
      room: '',
      visitedColiving: ''
    });

    // Validate all fields
    const newErrors = {
      name: !name ? 'Name is required' : '',
      email: !email ? 'Email is required' : !validateEmail(email) ? 'Invalid email format' : '',
      message: !message ? 'Message is required' : '',
      room: !selectedRoomId ? 'Please select a room' : '',
      visitedColiving: !visitedColiving ? 'Please select an option' : ''
    };

    setFormErrors(newErrors);
    
    if (Object.values(newErrors).some(error => error !== '')) {
      return;
    }

    if (!token) {
      setOpen(true);
      return;
    }

    try {
      // Call createNewBooking
      const bookingResponse = await createNewReservation(
        colivingId,
        selectedRoomId,
        checkinDate.format('YYYY-MM-DD'),
        checkoutDate.format('YYYY-MM-DD'),
        token  // Add the token to the booking request
      );
      // If booking is successful, proceed with email sending
      if (bookingResponse) {
        const templateParams = {
          from_name: name,
          from_email: email,
          message: message,
          subject: subject,
          checkinDate,
          checkoutDate,
          visitedColiving: visitedColiving === 'yes' ? 'Yes' : 'No',
        };

        await emailjs.send(
          process.env.REACT_APP_EMAIL_API_KEY,
          process.env.REACT_APP_EMAIL_TEMPLATE_KEY,
          templateParams,
          process.env.REACT_APP_EMAIL_PUBLIC_KEY
        );
        
        // Reset form fields
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
        setCheckinDate(null);
        setCheckoutDate(null);
        setIsSubmitDone(true);
      }
        // If booking is successful, redirect to the success URL
      if (bookingResponse && bookingResponse.status === 'success') {
      window.location.href = bookingResponse.session.url;
      return;
      }
    } catch (error) {
      console.error('Error creating booking or sending email:', error);
      // TODO: Show error message to the user
    }
  };

  return (
    <Container>
      <AuthDialog 
        open={open} 
        setOpen={setOpen} 
        errorTitle="Please login to book" 
      />
      <ColivingNameWrapper>
        <LogoWrapper src={colivingMadrid} alt="logo-coliving-madrid" />
        <h1>{coliving.name}</h1>
        <h2 
          onClick={() => {
            const formattedName = coliving.name?.toLowerCase().replace(/\s+/g, '-') || '';
            window.location.href = `/${formattedName}-blog`;
          }}
          style={{ cursor: 'pointer' }}
        >
          blog
        </h2>
      </ColivingNameWrapper>
      <p>Nestled in the heart of Spain, Coliving Madrid offers an immersive experience that seamlessly blends community, comfort, and culture. Our spaces are designed to be more than 
      just living quarters – they're vibrant hubs where like-minded individuals come together to create lasting connections.</p>
      <ImageGallery images={images}/>
      <p>From beautifully furnished common areas that encourage interaction to cozy private rooms that provide a personal sanctuary, every detail has been meticulously curated to foster a sense of belonging.</p>
      <p>In Coliving Madrid, you'll discover a dynamic environment that embraces both work and play. Whether you're a digital nomad, an artist seeking inspiration, or a traveler exploring new horizons, our space caters to diverse lifestyles.</p>
      <p>Engage in skill-sharing workshops, unwind in communal lounges, or venture out to explore the rich tapestry of Madrid's offerings. With the city's vibrant neighborhoods, historic landmarks, and culinary delights at your doorstep, Coliving Madrid is more than a living space – it's a vibrant lifestyle that encourages exploration, growth, and meaningful connections.</p>
      {mockedRooms.map((room, index) => (
        <Room
          key={index}
          name={room.name}
          description={room.description}
          image={room.image}
          alt={room.alt}
          roomId={room.roomId}
        />
      ))}
      <CalendarWrapper>
        <Calendar label='Check in' onChange={handleCheckinChange} minDate={today} value={undefined} sx={{ zIndex: 0 }} />
        <Calendar label='Check out' onChange={handleCheckoutChange} minDate={checkinDate} value={undefined} sx={{'z-index': '0'}}/>
      </CalendarWrapper>
      {isInvalidStay && <p style={{ color: 'red' }}>Minimum stay is 15 days</p>}
      {checkinDate && checkoutDate &&!isInvalidStay && 
      <FormWrapper onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <InputLabel id="room-select-label">Select a room</InputLabel>
          <Select
            labelId="room-select-label"
            id="room-select"
            value={selectedRoomId}
            label="Select a room"
            onChange={handleRoomChange}
            error={!!formErrors.room}
            sx={{ 
              minHeight: '56px',
              marginBottom: '10px'
            }}
          >
            <MenuItem value="">
              <em>Select a room</em>
            </MenuItem>
            {mockedRooms.map((room) => (
              <MenuItem key={room.roomId} value={room.roomId}>
                {room.name} - €{room.price}/night
              </MenuItem>
            ))}
          </Select>
          {formErrors.room && <p style={{ color: 'red', fontSize: '0.8em', margin: '0.2em 0' }}>{formErrors.room}</p>}
        </FormControl>

        <InputWrapper
          type="text"
          id="name"
          placeholder='Name'
          value={name}
          onChange={handleNameChange}
        />
        {formErrors.name && <p style={{ color: 'red', fontSize: '0.8em', margin: '0.2em 0' }}>{formErrors.name}</p>}

        <InputWrapper
          type="email"
          id="email"
          placeholder='Email'
          value={email}
          onChange={handleEmailChange}
        />
        {formErrors.email && <p style={{ color: 'red', fontSize: '0.8em', margin: '0.2em 0' }}>{formErrors.email}</p>}

        <TextareaWrapper
          id="message"
          value={message}
          onChange={handleMessageChange}
          placeholder='What are your expectations for joining our coliving?'
        />
        {formErrors.message && <p style={{ color: 'red', fontSize: '0.8em', margin: '0.2em 0' }}>{formErrors.message}</p>}

        <div>
          <p>Have you been to a Coliving before?</p>
          <label>
            <input 
              type="radio" 
              name="visitedColiving" 
              value="yes" 
              checked={visitedColiving === 'yes'} 
              onChange={() => {
                setVisitedColiving('yes');
                setFormErrors(prev => ({...prev, visitedColiving: ''}));
              }}
              style={{marginRight:'0.5em'}} 
            /> 
            Yes
          </label>
          <label style={{marginLeft:'1em'}}>
            <input 
              type="radio" 
              name="visitedColiving" 
              value="no" 
              checked={visitedColiving === 'no'} 
              onChange={() => setVisitedColiving('no')} 
              style={{marginRight:'0.5em'}} 
            /> 
            No
          </label>
        </div>
        {formErrors.visitedColiving && <p style={{ color: 'red', fontSize: '0.8em', margin: '0.2em 0' }}>{formErrors.visitedColiving}</p>}
        
        <ButtonWrapper type="submit">Book</ButtonWrapper>
      </FormWrapper>
      }
      {isSubmitDone && <p>booking done, check your email :)</p>}
        <Reviews reviews={coliving.reviews}/>
        <h3 style={{marginBottom:'1em'}}>Blogs of Coliving Madrid</h3>
        <a href="https://travelandtapas.com/coliving-spain/madrid/" rel="follow">Travel and tapas blog on our coliving</a>
        <p></p>
        <a href="https://travelandtapas.com/coliving-spaces-spain/coliving-madrid/" rel="follow">Travel and tapas  second blog of our coliving </a>
        <p></p>
        <a href="https://ebom.es/casas/la-casa-de-lu-un-coliving-en-rivas/" rel="follow">Ebom magazine blog on our coliving</a>
        <p></p>
      <h2>Our social networks</h2>
      <SocialNetworkContainer>
        <SocialNetworkLogosWrapper href="https://www.instagram.com/colivingmadrid/">
          <img src={logoInstagram} alt="Instagram Logo" />
        </SocialNetworkLogosWrapper>
      </SocialNetworkContainer>
    </Container>
  );
}
